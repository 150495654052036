export default {
  navTab: {
    title: 'JARVIS ',
    tab1: 'Start',
    tab2: 'WHO WE ARE',
    tab3: 'WORK WITH US',
    tab4: 'CONTACT US'
  },
  homeTop: {
    h3Title: 'OMNICHANNEL CONTACT CENTER',
    h3Title1: 'Excellent Human Resources',
    desc: 'JARVIS OUTSOURCING COMPANY LIMITED is an excellent human resources outsourcing service company based in Lagos, Nigeria, providing professional human resources outsourcing services to our customers'
  },
  homeBottom: {
    title: 'What we offer',
    menu1: 'EFFICIENCY',
    menuDesc1: 'We operate with a management model that implements a continuous improvement process in your operation and our staff, supported by Work Force Management, Training, Business Intelligence and the Command Center.',
    menu2: 'PLATAFORMA MULTICANAL',
    menuDesc2: 'Gestionamos las interacciones con tus clientes en una plataforma multicanal que permite contactar de forma adecuada a tus clientes, por los medios que ellos buscan y se ajustan a sus necesidades.We manage interactions with your customers on a multi-channel platform that allows you to contact your customers appropriately, by the means they seek and adjust to their needs.',
    menu3: 'EXPERT STAFF',
    menuDesc3: 'We have the attraction, recruitment and training of qualified personnel, as well as an organizational culture program focused on the well-being of our collaborators to promote their professional development.',
    menu4: 'THE BEST SOLUTION OF',
    menu4br: 'CONTACT',
    menuDesc4: 'We analyze and optimize your processes to be more efficient and help make your company profitable'
  },
  bottom: {
    title: 'JARVIS OUTSOURCING',
    email: 'Finance.JARVIS@outlook.com',
    facebook: 'Facebook: Dino Outsource',
    about: 'ABOUT US',
    joinUs: 'WORK WITH US',
    contact: 'CONTACT US',
    privacyTitle: 'quick links',
    privacy: 'Notice of Privacy'
  },
  aboutUs: {
    title: 'Company Profile',
    p1: 'Our Human Resourcing company is dedicated to helping businesses of all sizes find the right talent to achieve their goals. We specialize in providing customized staffing solutions to meet the unique needs of each client.  ',
    p2: `Our services include temporary staffing, direct hire, and temp-to-perm placements. At our company, we believe in investing in our employees and providing them with opportunities for growth and development. We offer competitive salaries and benefits packages, as well as ongoing training and support to help our employees succeed in their careers.   `,
    p4: `Our company has a rich history of providing innovative staffing solutions to businesses across a wide range of industries. We are proud to have built a reputation for excellence in the human resourcing industry, and we are committed to continuing to provide exceptional service to our clients and employees alike. `,
    p5: 'At Blue Connect, employees have the opportunity to work with cutting-edge technology and be a part of a team that is dedicated to innovation. The company offers competitive salaries, flexible work arrangements, and a supportive work environment. '
  },
  joinUs: {
    title: 'Recruitment',
    subTitle: 'HumanResources Manager',
    descTitle: 'Job Description',
    descSubTitle: '【HumanResources Manager】',
    content1: 'Due to expansion, an IMPORTANT COMPANY IN THE FINTECH BRANCH is en-',
    content2: 'we invite you to join our great team of telephone advisors!!',
    section1Title: 'We offer:',
    section1Desc1: 'Base salary 50k ~ 55K NGN',
    section1Desc2: 'Incentives for productivity from 20k ~ 100K NGN',
    section1Desc3: 'Punctuality Bonus 5000 NGN',
    section1Desc4: 'Referral Bonus 350 NGN',
    section1Desc5: 'Benefits of law from the first day',
    section1Desc6: 'Paid Training',
    section1Desc7: 'Free Coffee Break Service',
    section1Desc8: 'Dining room with soda machine (Terrace with a view of Chapultepec Castle)',
    section1Desc9: 'Game Rooms',
    section1Desc10: 'Modern Installations',
    section2Title: 'Requirements:',
    section2Desc1: '18 to 45 years old',
    section2Desc2: 'Secondary completed',
    section2Desc3: 'Documentation in order',
    section2Desc4: "No experience needed, we'll train you!!",
    section2Desc5: 'Shift from 9 to 6 from Monday to Sunday with rest during the week',
    vitae: 'How to submit (apply) your Curriculum Vitae:',
    vitaeGray: 'Application via Email',
    vitaeOrange: '“Finance.JARVIS@outlook.com”'
  },
  contactUs: {
    title: 'CONTACT US',
    p1: 'Company Address：',
    p2: 'Cto. Interior Melchor Ocampo 469, Anzures, Miguel Hidalgo, 11590 Ciudad',
    p3: 'from Mexico, CDMX',
    p4: 'Email:',
    p5: 'Finance.JARVIS@outlook.com',
    p6: 'Facebook：',
    p7: 'Dino Outsource'
  }
}
